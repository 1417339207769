

	.cookies_msg
	{
		width:100%;
	}
	.cookies_row
	{
		flex-direction: column;
		text-align:center;
	}
 	.cookies_msg .cookies_accept
 	{
	width:100%;
	margin: 20px 0 10px 0;
	text-align:center;
	padding:10px;
 	}

.header
{
	max-height:100%;
	padding-left:0px;
	padding-right:0px;
	padding-top:100px;
	background-position:75% center;
}
.header_row
{
	margin-bottom:100px;
}
.header_margin
{
	margin-bottom:10px;
}
.header_text
{
	background:rgba(0,0,0,0.5);
	width:min-content;
	padding:20px;
	font-size:48px;
}
.about_text
{
	padding-left:20px;
	padding-right:20px;
}

.header_section
{
	padding-left:20px;
	padding-right:20px;
}
.projects_home_grid
{
	padding-left:0;
	padding-right:0;
}
.item_4
{
	height:auto !important;
}
.project_item
{
	width:100% !important;
}
.project_image
{
	
}
.video_wrapper
{
	padding-bottom:58%;
	top:0;
}
.video_row
{
	flex-direction: column-reverse;
}

.media_item
{
	width:auto;
	padding-right:0;
	padding-left:0;
}
.content_wrapper
{
	padding-top:0;
}

/* ---- navbar -----------------*/
nav 
{
	position:absolute;
	height:auto;
}
.nav_brand 
{
	display:none !important;
}
.hamb
{
	margin-left:0;
}
.navbar
{
	flex-direction: row;
	padding:20px 20px 30px 20px;
	align-items: start;
}
.nav_menu
{
	opacity:1;
	display:none;
    flex-direction: row-reverse;
    align-items: baseline;
    justify-content: flex-end;
} 

.main_menu 
{
	flex-direction: column;
}
.social_menu 
{
	flex:1;
}
.side-menu:checked ~ .nav_menu
{
  display:flex;
}

.navbar:has(.side-menu:checked)
{
  background:rgba(0,0,0,0.65);
}

nav.open 
{
	position:relative;
}
nav.open .hamb
{
	display:block;
}
nav.open .navbar
{
	padding: 30px 20px 10px 20px;
}
nav.open .nav_menu 
{
	margin-bottom:30px;
}
nav.open .navbar:has(.side-menu:checked)
{
  background:white;
}

/* ---- about -----------------*/
#about_page
{
	margin-top:10px;
	margin-bottom:40px;
	padding:0 20px;
}
.about_row
{
	flex-direction: column;
}
.about_pic
{
	flex:auto;
}
.about_bio
{
	padding:20px 0;
}


/* ---- works -----------------*/
.projects_section_page
{
	margin-top:10px;
}
.page_head_section
{
	margin-left:20px;
	margin-right:20px;
	margin-bottom:30px;
}
.page_title_grid
{
	margin-left:20px;
	margin-right:20px;
}
.works_list
{
	flex-direction: column;
	padding:0;
}
.works_list .work_item
{
	width:auto;
	max-width: 100%;
}

/* ---- talks -----------------*/
#talks_page
{
	margin-top:10px;
}
#talks_grid
{
	margin:0 20px 80px 20px;
}
.talk_item
{
	width:100%;
	margin-bottom:30px;
}
.talk_wrapper
{
	padding:0;
}
/* ---- media -----------------*/
#media_page #media_grid
{
	padding:20px;
}

#media_lists
{
	padding:0 20px;
	margin-top:0;
	margin-bottom:60px;
	flex-direction:column;
}
.media_line
{
	width:auto;
}
.media_line .media_info
{
	padding:5px;
	margin:0;
}


/* ---- foot -----------------*/
footer
{
	padding:20px;
	flex-direction:column;
}
.foot_text_wrapper
{
	border:0;
}

footer span, footer a
{
	padding:0 !important;
	border-right:0 !important;
}

.menu_footer a
{
	margin:10px;
}
.social_footer a 
{
	margin:10px;
}

.foot_logo_wrapper
{
	margin-top:20px;
	width:100%;
	text-align:left;
}
.foot_logo_soko
{
	width:85px;
}
/* ---- projects -----------------*/
.projects_section_page
{
	padding:0;
	margin-bottom:70px;
}
.project_tag_container
{
	margin-bottom:20px;
	padding:0 20px;
	display:flex;
	flex-wrap:wrap;
}
.project_tag
{
	margin-bottom:15px;
	margin-right:15px;
}

.project_info svg
{
	height:60px !important;
}


/* ---- home -----------------*/


.header_subtext
{
	padding-right:20%;
	line-height:1.1;
	margin-bottom:0;
}
.section_title
{
	line-height:1.3;
}
.section_services
{
	padding:30px 25px 20px 25px;
}

.col_service_left
{
	width:100%;
	padding:0;
}
.col_service_right
{
	width:100%;
	padding:0;
}
.col_service_center
{
	order:-1;
}
.services_spinner
{
	margin:0;
}
.services_grid
{
	flex-direction:column;
}

.col_service_center svg
{
	position:relative;
	margin:0 0 50px 0;
}
.col_service_center .text
{
	position:absolute;
	width:100%;
	margin-top:60px;
}
.space_info
{
	padding:50px 25px;	
}
.space_content
{
	width:100%;
}
.space_img
{
	height:100%;
}
.section_header
{
	padding-left:25px;
}
.section_logos
{
	padding:0;
	margin-bottom:70px;
}
.row_logos
{
	padding:0 25px 0 25px;
	margin:0;
	width:100%;
}
.col_logos
{
	width:50%;
}
.col_logos img
{
	max-width:100%;
	width:100%;
	padding: 0 10px;
}


/* tablet portrait */
/*
@media (min-width: 768px) and (max-width: 1024px) 
{
	.project_item 
	{
		width:50% !important;
	}
	.project_image
	{
		height:40vw;
	}
	.media_item {
		width: 50%;
		padding-right: 5px;
		padding-left: 5px;
	}
	.about_bio {
		padding: 40px 0 20px 0;
	}
	.works_list .work_item {
		width: 50%;
		max-width: 50%;
	}
	.works_list {
		flex-direction: row;
		margin-bottom:20px;
	}
	#media_list {
		padding: 0 30px;
		margin-top: 0;
		margin-bottom: 60px;
		flex-direction: row;
	}
	.media_line {
		width: 33%;
		padding:10px 5px;
	}
	.w100
	{
		width:100% !important;
	}
}
*/
/* tablet landscape */
/*
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) 
{
	.header_row {
		margin-bottom: 80px;
	}
	.about_text
	{
		padding-right:20vw;
	}
	.about_bio {
		padding: 40px 0 20px 40px;
	}
	.project_item
	{
		width:33% !important;
	}
	.project_image
	{
		height:30vw;
	}
	.media_item
	{
		width:33%;
	}

	.works_list .work_photo 
	{
		min-height:auto;
		height:40vw;
	}
	.works_list .work_item
	{
		width:50%;
		max-width:50%;
	}
	#media_page #media_grid
	{
		padding:40px 50px;
	}
	.media_line
	{
		width:33%;
	}
	.w100
	{
		width:100% !important;
	}
}
*/