.cookies_msg {
  width: 100%;
}

.cookies_row {
  text-align: center;
  flex-direction: column;
}

.cookies_msg .cookies_accept {
  text-align: center;
  width: 100%;
  margin: 20px 0 10px;
  padding: 10px;
}

.header {
  background-position: 75%;
  max-height: 100%;
  padding-top: 100px;
  padding-left: 0;
  padding-right: 0;
}

.header_row {
  margin-bottom: 100px;
}

.header_margin {
  margin-bottom: 10px;
}

.header_text {
  background: #00000080;
  width: min-content;
  padding: 20px;
  font-size: 48px;
}

.about_text, .header_section {
  padding-left: 20px;
  padding-right: 20px;
}

.projects_home_grid {
  padding-left: 0;
  padding-right: 0;
}

.item_4 {
  height: auto !important;
}

.project_item {
  width: 100% !important;
}

.video_wrapper {
  padding-bottom: 58%;
  top: 0;
}

.video_row {
  flex-direction: column-reverse;
}

.media_item {
  width: auto;
  padding-left: 0;
  padding-right: 0;
}

.content_wrapper {
  padding-top: 0;
}

nav {
  height: auto;
  position: absolute;
}

.nav_brand {
  display: none !important;
}

.hamb {
  margin-left: 0;
}

.navbar {
  flex-direction: row;
  align-items: start;
  padding: 20px 20px 30px;
}

.nav_menu {
  opacity: 1;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: baseline;
  display: none;
}

.main_menu {
  flex-direction: column;
}

.social_menu {
  flex: 1;
}

.side-menu:checked ~ .nav_menu {
  display: flex;
}

.navbar:has(.side-menu:checked) {
  background: #000000a6;
}

nav.open {
  position: relative;
}

nav.open .hamb {
  display: block;
}

nav.open .navbar {
  padding: 30px 20px 10px;
}

nav.open .nav_menu {
  margin-bottom: 30px;
}

nav.open .navbar:has(.side-menu:checked) {
  background: #fff;
}

#about_page {
  margin-top: 10px;
  margin-bottom: 40px;
  padding: 0 20px;
}

.about_row {
  flex-direction: column;
}

.about_pic {
  flex: auto;
}

.about_bio {
  padding: 20px 0;
}

.projects_section_page {
  margin-top: 10px;
}

.page_head_section {
  margin-bottom: 30px;
  margin-left: 20px;
  margin-right: 20px;
}

.page_title_grid {
  margin-left: 20px;
  margin-right: 20px;
}

.works_list {
  flex-direction: column;
  padding: 0;
}

.works_list .work_item {
  width: auto;
  max-width: 100%;
}

#talks_page {
  margin-top: 10px;
}

#talks_grid {
  margin: 0 20px 80px;
}

.talk_item {
  width: 100%;
  margin-bottom: 30px;
}

.talk_wrapper {
  padding: 0;
}

#media_page #media_grid {
  padding: 20px;
}

#media_lists {
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 60px;
  padding: 0 20px;
}

.media_line {
  width: auto;
}

.media_line .media_info {
  margin: 0;
  padding: 5px;
}

footer {
  flex-direction: column;
  padding: 20px;
}

.foot_text_wrapper {
  border: 0;
}

footer span, footer a {
  border-right: 0 !important;
  padding: 0 !important;
}

.menu_footer a, .social_footer a {
  margin: 10px;
}

.foot_logo_wrapper {
  text-align: left;
  width: 100%;
  margin-top: 20px;
}

.foot_logo_soko {
  width: 85px;
}

.projects_section_page {
  margin-bottom: 70px;
  padding: 0;
}

.project_tag_container {
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 0 20px;
  display: flex;
}

.project_tag {
  margin-bottom: 15px;
  margin-right: 15px;
}

.project_info svg {
  height: 60px !important;
}

.header_subtext {
  margin-bottom: 0;
  padding-right: 20%;
  line-height: 1.1;
}

.section_title {
  line-height: 1.3;
}

.section_services {
  padding: 30px 25px 20px;
}

.col_service_left, .col_service_right {
  width: 100%;
  padding: 0;
}

.col_service_center {
  order: -1;
}

.services_spinner {
  margin: 0;
}

.services_grid {
  flex-direction: column;
}

.col_service_center svg {
  margin: 0 0 50px;
  position: relative;
}

.col_service_center .text {
  width: 100%;
  margin-top: 60px;
  position: absolute;
}

.space_info {
  padding: 50px 25px;
}

.space_content {
  width: 100%;
}

.space_img {
  height: 100%;
}

.section_header {
  padding-left: 25px;
}

.section_logos {
  margin-bottom: 70px;
  padding: 0;
}

.row_logos {
  width: 100%;
  margin: 0;
  padding: 0 25px;
}

.col_logos {
  width: 50%;
}

.col_logos img {
  width: 100%;
  max-width: 100%;
  padding: 0 10px;
}

/*# sourceMappingURL=index.e3293463.css.map */
